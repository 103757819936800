import React, { useState, useCallback, useMemo } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import dayjs from "dayjs";
import { Link } from "../link";
import NetReviewStars from "../elements/netreviews-stars";
import ArrowScroller from "./arrow-scroller";
import Countdown from "./countdown";
import { isFlusso, getProductName } from "../../utility/config-flussi-switch-in";
import NavTabs from "./nav-tabs";
import Switch from "./switch";
import Cookies from "js-cookie";
import HeaderCardFeatures from "./header-card-features";

const Card = ({ card }) => {
  const [selectedCard, setSelectedCard] = useState(0);
  const [selectActive, setSelectActive] = useState(true);
  const toggleActive = useCallback(() => setSelectActive((value) => !value), [setSelectActive]);

  const eleFeaturesSw1 = useMemo(() => {
    const features = card?.content?.switchTitle1?.content?.caratt1Ele
      ?.map((element) => ({
        label: element?.value,
        value: element?.content?.valueField1Ele?.value
          ?.replace(
            /@corrispettivoEnergia/g,
            card?.content?.prodottoCRM?.corrispettivoEnergia
              ? card.content.prodottoCRM.corrispettivoEnergia
              : ""
          )
          ?.replace(
            /@corrispettivoF1/g,
            card?.content?.prodottoCRM?.corrispettivoF1
              ? card.content.prodottoCRM.corrispettivoF1
              : ""
          )
          ?.replace(
            /@corrispettivoF2/g,
            card?.content?.prodottoCRM?.corrispettivoF2
              ? card.content.prodottoCRM.corrispettivoF2
              : ""
          )
          ?.replace(
            /@corrispettivoF3/g,
            card?.content?.prodottoCRM?.corrispettivoF3
              ? card.content.prodottoCRM.corrispettivoF3
              : ""
          )
          ?.replace(
            /@spreadLuce/g,
            card?.content?.prodottoCRM?.spreadLuce ? card.content.prodottoCRM.spreadLuce : ""
          )
          ?.replace(
            /@consumoLuceLordoPerdite/g,
            card?.content?.prodottoCRM?.spreadLuceLordoPerdite
              ? card.content.prodottoCRM.spreadLuceLordoPerdite
              : ""
          )
          ?.replace(
            /@spreadF1/g,
            card?.content?.prodottoCRM?.spreadF1 ? card.content.prodottoCRM.spreadF1 : ""
          )
          ?.replace(
            /@spreadF2/g,
            card?.content?.prodottoCRM?.spreadF2 ? card.content.prodottoCRM.spreadF2 : ""
          )
          ?.replace(
            /@spreadF3/g,
            card?.content?.prodottoCRM?.spreadF3 ? card.content.prodottoCRM.spreadF3 : ""
          )
          ?.replace(
            /@quotaPvolkWh/g,
            card?.content?.prodottoCRM?.quotaPvolkWh ? card.content.prodottoCRM.quotaPvolkWh : ""
          )
          ?.replace(
            /@quotaPvolF1kWh/g,
            card?.content?.prodottoCRM?.quotaPvolF1kWh
              ? card.content.prodottoCRM.quotaPvolF1kWh
              : ""
          )
          ?.replace(
            /@quotaPvolF2kWh/g,
            card?.content?.prodottoCRM?.quotaPvolF2kWh
              ? card.content.prodottoCRM.quotaPvolF2kWh
              : ""
          )
          ?.replace(
            /@quotaPvolF3kWh/g,
            card?.content?.prodottoCRM?.quotaPvolF3kWh
              ? card.content.prodottoCRM.quotaPvolF3kWh
              : ""
          )
          ?.replace(
            /@quotaAlfakWh/g,
            card?.content?.prodottoCRM?.quotaAlfakWh ? card.content.prodottoCRM.quotaAlfakWh : ""
          )
          ?.replace(
            /@quotaAlfaF1kWh/g,
            card?.content?.prodottoCRM?.quotaAlfaF1kWh
              ? card.content.prodottoCRM.quotaAlfaF1kWh
              : ""
          )
          ?.replace(
            /@quotaAlfaF2kWh/g,
            card?.content?.prodottoCRM?.quotaAlfaF2kWh
              ? card.content.prodottoCRM.quotaAlfaF2kWh
              : ""
          )
          ?.replace(
            /@quotaAlfaF3kWh/g,
            card?.content?.prodottoCRM?.quotaAlfaF3kWh
              ? card.content.prodottoCRM.quotaAlfaF3kWh
              : ""
          )
          ?.replace(
            /@corrispettivoBiorariaF1/g,
            card?.content?.prodottoCRM?.corrispettivoBiorariaF1
              ? card.content.prodottoCRM.corrispettivoBiorariaF1
              : ""
          )
          ?.replace(
            /@corrispettivoBiorariaF23/g,
            card?.content?.prodottoCRM?.corrispettivoBiorariaF23
              ? card.content.prodottoCRM.corrispettivoBiorariaF23
              : ""
          )
          ?.replace(
            /@quotaCommFissaDomesticoLuceMensile/g,
            card?.content?.prodottoCRM?.quotaCommFissaDomesticoLuceMensile
              ? card.content.prodottoCRM.quotaCommFissaDomesticoLuceMensile
              : ""
          )
          ?.replace(
            /@quotaCommFissaDomesticoLuce/g,
            card?.content?.prodottoCRM?.quotaCommFissaDomesticoLuce
              ? card.content.prodottoCRM.quotaCommFissaDomesticoLuce
              : ""
          )
          ?.replace(
            /@quotaCommVariabileDomesticoLuce/g,
            card?.content?.prodottoCRM?.quotaCommVariabileDomesticoLuce
              ? card.content.prodottoCRM.quotaCommVariabileDomesticoLuce
              : ""
          ),
        hr: element?.content?.hrSw1Ele?.value,
        isBold: element?.content?.isBold1Ele?.value,
        isTooltip: element?.content?.isTooltip1Ele?.value,
        tooltip: element?.content?.isTooltip1Ele?.content?.carattTooltipSw1Ele?.value,
      }))
      .filter((item) => !item.value.includes("@"));

    return features;
  }, [card]);

  const gasFeaturesSw1 = useMemo(() => {
    const features = card?.content?.switchTitle1?.content?.caratt1Gas
      ?.map((element) => ({
        label: element?.value,
        value: element?.content?.valueField1Gas?.value
          ?.replace(
            /@componentePrezzoFisso/g,
            card?.content?.prodottoCRM?.componentePrezzoFisso
              ? card.content.prodottoCRM.componentePrezzoFisso
              : ""
          )
          ?.replace(
            /@quotaPvolSmc/g,
            card?.content?.prodottoCRM?.quotaPvolSmc ? card.content.prodottoCRM.quotaPvolSmc : ""
          )
          ?.replace(
            /@spreadGas/g,
            card?.content?.prodottoCRM?.spreadGas ? card.content.prodottoCRM.spreadGas : ""
          )
          ?.replace(
            /@quotaCommFissaDomesticoGasMensile/g,
            card?.content?.prodottoCRM?.quotaCommFissaDomesticoGasMensile
              ? card.content.prodottoCRM.quotaCommFissaDomesticoGasMensile
              : ""
          )
          ?.replace(
            /@quotaCommFissaDomesticoGas/g,
            card?.content?.prodottoCRM?.quotaCommFissaDomesticoGas
              ? card.content.prodottoCRM.quotaCommFissaDomesticoGas
              : ""
          )
          ?.replace(
            /@quotaCommVariabileDomesticoGas/g,
            card?.content?.prodottoCRM?.quotaCommVariabileDomesticoGas
              ? card.content.prodottoCRM.quotaCommVariabileDomesticoGas
              : ""
          ),
        hr: element?.content?.hrSw1Gas?.value,
        isBold: element?.content?.isBold1Gas?.value,
        isTooltip: element?.content?.isTooltip1Gas?.value,
        tooltip: element?.content?.isTooltip1Gas?.content?.carattTooltipSw1Gas?.value,
      }))
      .filter((item) => !item.value.includes("@"));

    return features;
  }, [card]);

  const eleFeaturesSw2 = useMemo(() => {
    const features = card?.content?.switchPresent?.content?.switchTitle2?.content?.caratt2Ele
      ?.map((element) => ({
        label: element?.value,
        value: element?.content?.valueField2Ele?.value
          ?.replace(
            /@corrispettivoEnergia/g,
            card?.content?.prodottoCRM?.corrispettivoEnergia
              ? card.content.prodottoCRM.corrispettivoEnergia
              : ""
          )
          ?.replace(
            /@corrispettivoF1/g,
            card?.content?.prodottoCRM?.corrispettivoF1
              ? card.content.prodottoCRM.corrispettivoF1
              : ""
          )
          ?.replace(
            /@corrispettivoF2/g,
            card?.content?.prodottoCRM?.corrispettivoF2
              ? card.content.prodottoCRM.corrispettivoF2
              : ""
          )
          ?.replace(
            /@corrispettivoF3/g,
            card?.content?.prodottoCRM?.corrispettivoF3
              ? card.content.prodottoCRM.corrispettivoF3
              : ""
          )
          ?.replace(
            /@spreadLuce/g,
            card?.content?.prodottoCRM?.spreadLuce ? card.content.prodottoCRM.spreadLuce : ""
          )
          ?.replace(
            /@consumoLuceLordoPerdite/g,
            card?.content?.prodottoCRM?.spreadLuceLordoPerdite
              ? card.content.prodottoCRM.spreadLuceLordoPerdite
              : ""
          )
          ?.replace(
            /@spreadF1/g,
            card?.content?.prodottoCRM?.spreadF1 ? card.content.prodottoCRM.spreadF1 : ""
          )
          ?.replace(
            /@spreadF2/g,
            card?.content?.prodottoCRM?.spreadF2 ? card.content.prodottoCRM.spreadF2 : ""
          )
          ?.replace(
            /@spreadF3/g,
            card?.content?.prodottoCRM?.spreadF3 ? card.content.prodottoCRM.spreadF3 : ""
          )
          ?.replace(
            /@quotaPvolkWh/g,
            card?.content?.prodottoCRM?.quotaPvolkWh ? card.content.prodottoCRM.quotaPvolkWh : ""
          )
          ?.replace(
            /@quotaPvolF1kWh/g,
            card?.content?.prodottoCRM?.quotaPvolF1kWh
              ? card.content.prodottoCRM.quotaPvolF1kWh
              : ""
          )
          ?.replace(
            /@quotaPvolF2kWh/g,
            card?.content?.prodottoCRM?.quotaPvolF2kWh
              ? card.content.prodottoCRM.quotaPvolF2kWh
              : ""
          )
          ?.replace(
            /@quotaPvolF3kWh/g,
            card?.content?.prodottoCRM?.quotaPvolF3kWh
              ? card.content.prodottoCRM.quotaPvolF3kWh
              : ""
          )
          ?.replace(
            /@quotaAlfakWh/g,
            card?.content?.prodottoCRM?.quotaAlfakWh ? card.content.prodottoCRM.quotaAlfakWh : ""
          )
          ?.replace(
            /@quotaAlfaF1kWh/g,
            card?.content?.prodottoCRM?.quotaAlfaF1kWh
              ? card.content.prodottoCRM.quotaAlfaF1kWh
              : ""
          )
          ?.replace(
            /@quotaAlfaF2kWh/g,
            card?.content?.prodottoCRM?.quotaAlfaF2kWh
              ? card.content.prodottoCRM.quotaAlfaF2kWh
              : ""
          )
          ?.replace(
            /@quotaAlfaF3kWh/g,
            card?.content?.prodottoCRM?.quotaAlfaF3kWh
              ? card.content.prodottoCRM.quotaAlfaF3kWh
              : ""
          )
          ?.replace(
            /@corrispettivoBiorariaF1/g,
            card?.content?.prodottoCRM?.corrispettivoBiorariaF1
              ? card.content.prodottoCRM.corrispettivoBiorariaF1
              : ""
          )
          ?.replace(
            /@corrispettivoBiorariaF23/g,
            card?.content?.prodottoCRM?.corrispettivoBiorariaF23
              ? card.content.prodottoCRM.corrispettivoBiorariaF23
              : ""
          )
          ?.replace(
            /@quotaCommFissaDomesticoLuceMensile/g,
            card?.content?.prodottoCRM?.quotaCommFissaDomesticoLuceMensile
              ? card.content.prodottoCRM.quotaCommFissaDomesticoLuceMensile
              : ""
          )
          ?.replace(
            /@quotaCommFissaDomesticoLuce/g,
            card?.content?.prodottoCRM?.quotaCommFissaDomesticoLuce
              ? card.content.prodottoCRM.quotaCommFissaDomesticoLuce
              : ""
          )
          ?.replace(
            /@quotaCommVariabileDomesticoLuce/g,
            card?.content?.prodottoCRM?.quotaCommVariabileDomesticoLuce
              ? card.content.prodottoCRM.quotaCommVariabileDomesticoLuce
              : ""
          ),
        hr: element?.content?.hrSw2Ele?.value,
        isBold: element?.content?.isBold2Ele?.value,
        isTooltip: element?.content?.isTooltip2Ele?.value,
        tooltip: element?.content?.isTooltip2Ele?.content?.carattTooltipSw2Ele?.value,
      }))
      .filter((item) => !item.value.includes("@"));

    return features;
  }, [card]);

  const gasFeaturesSw2 = useMemo(() => {
    const features = card?.content?.switchPresent?.content?.switchTitle2?.content?.caratt2Gas
      ?.map((element) => ({
        label: element?.value,
        value: element?.content?.valueField2Gas?.value
          ?.replace(
            /@componentePrezzoFisso/g,
            card?.content?.prodottoCRM?.componentePrezzoFisso
              ? card.content.prodottoCRM.componentePrezzoFisso
              : ""
          )
          ?.replace(
            /@quotaPvolSmc/g,
            card?.content?.prodottoCRM?.quotaPvolSmc ? card.content.prodottoCRM.quotaPvolSmc : ""
          )
          ?.replace(
            /@spreadGas/g,
            card?.content?.prodottoCRM?.spreadGas ? card.content.prodottoCRM.spreadGas : ""
          )
          ?.replace(
            /@quotaCommFissaDomesticoGasMensile/g,
            card?.content?.prodottoCRM?.quotaCommFissaDomesticoGasMensile
              ? card.content.prodottoCRM.quotaCommFissaDomesticoGasMensile
              : ""
          )
          ?.replace(
            /@quotaCommFissaDomesticoGas/g,
            card?.content?.prodottoCRM?.quotaCommFissaDomesticoGas
              ? card.content.prodottoCRM.quotaCommFissaDomesticoGas
              : ""
          )
          ?.replace(
            /@quotaCommVariabileDomesticoGas/g,
            card?.content?.prodottoCRM?.quotaCommVariabileDomesticoGas
              ? card.content.prodottoCRM.quotaCommVariabileDomesticoGas
              : ""
          ),
        hr: element?.content?.hrSw2Gas?.value,
        isBold: element?.content?.isBold2Gas?.value,
        isTooltip: element?.content?.isTooltip2Gas?.value,
        tooltip: element?.content?.isTooltip2Gas?.content?.carattTooltipSw2Gas?.value,
      }))
      .filter((item) => !item.value.includes("@"));

    return features;
  }, [card]);

  return (
    <div className={classNames("card-mono d-flex flex-column align-items-center")}>
      <div className="card-offer d-none d-block mx-3 mx-md-0">
        <div className="text-center">
          <div className="card-offer__title">{card?.value}</div>
          <div className="sticky-footer__title px-lg-2">
            {card.content?.validita?.value
              ? card.content?.validita?.value
              : card.content.prodottoCRM?.dataFineValidita &&
                "Fino al " + card.content.prodottoCRM.dataFineValidita}
          </div>
          <div className="sticky-footer__price-descr text-center">
            {card.content?.sottotitoloCard?.value}
          </div>
        </div>
        <div className="card-offer__sez">
          <NavTabs
            tabs={card.content?.commodity}
            selectedTab={selectedCard}
            setTab={setSelectedCard}
          />
        </div>
        {card.content?.genericDescr.value && (
          <div className="card-offer__sez">
            <div className="card-offer__feature-text card-offer__feature-text--bold">
              {card.content?.genericDescr.value}
            </div>
          </div>
        )}
        {/* Switch mono/fasce */}
        {card?.content?.switchPresent?.value &&
          card.content.commodity?.[selectedCard].content.tipoCommodity?.value?.[0] !== "gas" && (
            <div className="card-offer__sez">
              <div className="d-flex align-items-center justify-content-between">
                <div
                  className={classNames("info d-flex align-items-center", {
                    "info--active": selectActive,
                  })}
                >
                  <div className="info-label-small">{card?.content?.switchTitle1?.value}</div>
                  <div
                    className={`card-offer__tooltip${
                      selectActive ? " active" : ""
                    } d-none d-md-block`}
                  >
                    <span className="card-offer__tooltip-label">i</span>
                    <div className="card-offer__tooltip-text">
                      {card?.content?.switchTitle1?.content?.firstTooltip?.value}
                    </div>
                  </div>
                </div>
                <div>
                  <Switch active={!selectActive} toggleFun={toggleActive} />
                </div>
                <div
                  className={classNames("info d-flex align-items-center", {
                    "info--active": !selectActive,
                  })}
                >
                  <div
                    className={`card-offer__tooltip${
                      selectActive ? "" : " active"
                    } d-none d-md-block`}
                  >
                    <span className="card-offer__tooltip-label">i</span>
                    <div className="card-offer__tooltip-text">
                      {
                        card?.content?.switchPresent?.content?.switchTitle2?.content?.secondTooltip
                          ?.value
                      }
                    </div>
                  </div>
                  <div className="info-label-small text-right">
                    {card?.content?.switchPresent?.content?.switchTitle2?.value}
                  </div>
                </div>
              </div>
            </div>
          )}
        {/* Caratteristiche **/}
        <div className="card-offer__sez">
          {(card?.content?.commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] ===
            "dual" ||
            card?.content?.commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] ===
              "luce") &&
            selectActive && (
              <HeaderCardFeatures
                features={eleFeaturesSw1}
                dualCommodity={
                  card?.content?.commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] ===
                  "dual"
                }
              />
            )}
          {(card?.content?.commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] ===
            "dual" ||
            card?.content?.commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] ===
              "gas") &&
            selectActive && (
              <HeaderCardFeatures
                features={gasFeaturesSw1}
                dualCommodity={
                  card?.content?.commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] ===
                  "dual"
                }
              />
            )}
          {(card?.content?.commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] ===
            "dual" ||
            card?.content?.commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] ===
              "luce") &&
            !selectActive && (
              <HeaderCardFeatures
                features={eleFeaturesSw2}
                dualCommodity={
                  card?.content?.commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] ===
                  "dual"
                }
              />
            )}
          {(card?.content?.commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] ===
            "dual" ||
            card?.content?.commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] ===
              "gas") &&
            !selectActive && (
              <HeaderCardFeatures
                features={gasFeaturesSw2}
                dualCommodity={
                  card?.content?.commodity?.[selectedCard]?.content?.tipoCommodity?.value?.[0] ===
                  "dual"
                }
              />
            )}
        </div>
        {card?.content?.noteCard?.value && (
          <div className="tab-box__text-sub mb-2">
            <span dangerouslySetInnerHTML={{ __html: card?.content?.noteCard?.value }} />
          </div>
        )}
      </div>
    </div>
  );
};

const SlideHeaderContent = ({
  headerImg,
  headerRatio,
  headerImgStyle,
  wrapped,
  flex,
  logo,
  title,
  titleMobile,
  description,
  productImage,
  productStars,
  cta,
  ctaFlussoAction,
  arrowScroller,
  countdown,
  card,
  descrizioneHtml,
  sottotitoloDescr,
  ctaList,
  isConfigurazione,
}) => {
  return (
    <>
      <div className="slide-header__content-wrapper position-relative">
        <GatsbyImage
          className="slide-header__image"
          image={headerImg}
          alt=""
          style={{
            ...headerImgStyle,
            ...(headerRatio && { "--padding": `${120 / headerRatio}%` }),
          }}
          loading="eager"
        />
        <div
          className={classNames("slide-header__content", {
            "wrapper-container": wrapped,
            "slide-header__content--flex": flex,
          })}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                {logo && (
                  <div className="slide-header__logo">
                    <GatsbyImage image={getImage(logo)} alt="Engie" />
                  </div>
                )}
                {title && (
                  <div
                    className={classNames(
                      "slide-header__title",
                      titleMobile && "d-none d-md-block"
                    )}
                    dangerouslySetInnerHTML={{ __html: "<h1>" + title + "</h1>" }}
                  />
                )}
                {titleMobile && (
                  <div
                    className="slide-header__title d-md-none"
                    dangerouslySetInnerHTML={{ __html: titleMobile }}
                  />
                )}
                <div
                  className={classNames("slide-header__descr", !description && "d-none d-md-block")}
                >
                  <div dangerouslySetInnerHTML={{ __html: description }}></div>
                  {productImage?.image && (
                    <GatsbyImage
                      image={productImage.image}
                      imgStyle={{ objectPosition: "top right" }}
                      alt={productImage.descr || ""}
                    />
                  )}
                  {productStars?.show && productStars?.prodId && (
                    <NetReviewStars dataProductId={productStars.prodId} />
                  )}
                </div>

                {/*Gestione CTA*/}
                <div className={classNames("slide-header text-center text-md-left mb-0")}>
                  <div style={{ display: "inline-block" }}>
                    {((ctaList?.[0]?.value &&
                      (isConfigurazione || isConfigurazione === undefined) &&
                      ctaList?.[0]?.content?.linkCtaNew?.value &&
                      ctaList?.[0]?.content?.linkCtaNew?.value !== "#") ||
                      ctaList?.[0]?.content?.nameProductSimulatore?.value) && (
                      <div className="slide-header__link pt-4 text-center text-md-left">
                        {ctaList[0].content.nameProductSimulatore?.value ? (
                          <button
                            title={ctaList[0].value}
                            onClick={() => {
                              Cookies.set(
                                "simulatoreNomeProdotto",
                                ctaList[0].content.nameProductSimulatore.value
                              );
                              window.open("/simulatore-bolletta");
                            }}
                            className={classNames(
                              "cta-link w-100 px-4 py-2 text-center text-nowrap my-1 my-md-0 " +
                                ctaList[0].content.tipoCtaNew?.value
                            )}
                          >
                            {ctaList[0].value}
                          </button>
                        ) : isFlusso(ctaList[0].content.linkCtaNew.value) ? (
                          <button
                            title={ctaList[0].value}
                            className={classNames(
                              "cta-link w-100 px-4 py-2 text-center text-nowrap my-1 my-md-0 " +
                                ctaList[0].content.tipoCtaNew?.value
                            )}
                            onClick={() =>
                              ctaFlussoAction(getProductName(ctaList[0].content.linkCtaNew?.value))
                            }
                          >
                            {ctaList[0].value}
                          </button>
                        ) : (
                          <Link
                            to={ctaList[0].content.linkCtaNew.value}
                            title={ctaList[0].value}
                            className={classNames(
                              "cta-link w-100 px-4 py-2 text-center text-nowrap my-1 my-md-0 " +
                                ctaList[0].content.tipoCtaNew?.value
                            )}
                          >
                            {ctaList[0].value}
                          </Link>
                        )}
                        {ctaList[0]?.content?.ctaNewSubtitle?.value && (
                          <div className="cta-subtitle">
                            {ctaList[0].content.ctaNewSubtitle.value}
                          </div>
                        )}
                      </div>
                    )}
                    {((ctaList?.[1]?.value &&
                      ctaList?.[1]?.content?.linkCtaNew?.value &&
                      ctaList?.[1]?.content?.linkCtaNew?.value !== "#") ||
                      ctaList?.[1]?.content?.nameProductSimulatore?.value) && (
                      <div className="slide-header__link pt-1 text-center text-md-left">
                        {ctaList[1].content.nameProductSimulatore?.value ? (
                          <button
                            title={ctaList[1].value}
                            onClick={() => {
                              Cookies.set(
                                "simulatoreNomeProdotto",
                                ctaList[1].content.nameProductSimulatore.value
                              );
                              window.open("/simulatore-bolletta");
                            }}
                            className={classNames(
                              "cta-link w-100 px-4 py-2 text-center text-nowrap my-1 my-md-0 " +
                                ctaList[1].content.tipoCtaNew?.value
                            )}
                          >
                            {ctaList[1].value}
                          </button>
                        ) : isFlusso(ctaList[1].content.linkCtaNew.value) ? (
                          <button
                            title={ctaList[1].value}
                            className={classNames(
                              "cta-link w-100 px-4 py-2 text-center text-nowrap my-1 my-md-0 " +
                                ctaList[1].content.tipoCtaNew?.value
                            )}
                            onClick={() =>
                              ctaFlussoAction(getProductName(ctaList[1].content.linkCtaNew?.value))
                            }
                          >
                            {ctaList[1].value}
                          </button>
                        ) : (
                          /* Se è un click to call viene nascosta da desktop */
                          <Link
                            to={ctaList[1].content.linkCtaNew.value}
                            title={ctaList[1].value}
                            className={classNames(
                              "cta-link w-100 px-4 py-2 text-center text-nowrap my-1 my-md-0 " +
                                ctaList[1].content.tipoCtaNew?.value,
                              ctaList[1]?.content?.linkCtaNew?.value.startsWith("tel") &&
                                "d-block d-xl-none"
                            )}
                          >
                            {ctaList[1].value}
                          </Link>
                        )}
                        {ctaList[1]?.content?.ctaNewSubtitle?.value && (
                          <div className="cta-subtitle">
                            {ctaList[1].content.ctaNewSubtitle.value}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {/*Fine gestione CTA*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      {arrowScroller?.value && <ArrowScroller scroll={arrowScroller?.scroll} />}
      {dayjs(countdown?.value).isAfter(dayjs()) && (
        <div
          className={classNames("slide-header__countdown my-2 my-md-0 ", {
            "d-md-block": dayjs(countdown?.value).isAfter(dayjs()),
            "slide-header__countdown--without-card": !card?.value,
          })}
        >
          <Countdown date={countdown?.value} color={countdown?.content?.cdownColor?.value} />
        </div>
      )}

      {card?.value && <Card card={card} />}

      {descrizioneHtml?.value && (
        <div className="container-fluid">
          <div className="col-xl-7 px-3 d-flex flex-column justify-content-center">
            <div className="tab-box__text">
              {descrizioneHtml?.value && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: descrizioneHtml.value,
                  }}
                />
              )}
              {sottotitoloDescr?.value && (
                <div
                  className="tab-box__text-sub mb-2"
                  dangerouslySetInnerHTML={{
                    __html: sottotitoloDescr.value,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SlideHeaderContent;
