import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Link } from "../components/link";
import FooterSites from "./elements/footer-sites";
import { onetrustShowSettings } from "../utility/onetrust";
import { dataLayerPush } from "../utility/dataLayerUtils";
import Icon from "./elements/icon-svg";

const pushAppBanner = (e) => {
  dataLayerPush({
    event: "Area pubblica",
    EventCategory: "Area pubblica",
    EventAction: "Download App",
    EventLabel: e.currentTarget.name,
  });
};

const Footer = () => {
  const { megaFooter, shortFooter } = useStaticQuery(graphql`
    fragment FooterPage on LiferayWebContent {
      ... on LiferayJskLayoutPaginaGenerica {
        title {
          value
        }
        link
      }
      ... on LiferayJskLayoutPaginaProdotto {
        title {
          value
        }
        link
      }
      ... on LiferayJskLayoutPaginaHub {
        title {
          value
        }
        link
      }
      ... on LiferayJskLayoutLandingOfferte {
        title {
          value
        }
        link
      }
      ... on LiferayJskPaginaLink {
        title {
          value
        }
        pageLink: link {
          value
        }
        document {
          node {
            publicURL
          }
        }
      }
      ... on LiferayJskLayoutPaginaPrivacy {
        link
      }
    }
    {
      megaFooter: liferayJskMenuMegaFooter {
        title {
          value
          content {
            titlePage {
              value {
                ...FooterPage
              }
            }
            pagine {
              value {
                ...FooterPage
              }
              content {
                pageName {
                  value
                }
              }
            }
          }
        }
      }
      shortFooter: liferayJskMenuShortFooter {
        title {
          value
          content {
            page {
              value {
                ...FooterPage
              }
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <div className="prefooter">
        <div className="wrap">
          <div className="container-fluid">
            <div className="row">
              {megaFooter?.title.map((item, i) => (
                <React.Fragment key={i}>
                  {i === megaFooter.title.length - 1 && (
                    <div className="col-12 col-md-6 col-lg-2 text-center text-md-left mt-4 mt-lg-0">
                      <h5>La nostra app ENGIE Italia</h5>
                      <div className="pl-4">
                        <a
                          href="https://apps.apple.com/it/app/engie-luce-gas-e-servizi/id1019118965?mt=8"
                          target="_blank"
                          rel="noreferrer"
                          name="Badge Apple Store - 5.3 - Footer"
                          onClick={pushAppBanner}
                          className="prefooter__btn d-inline-block d-md-block mr-2 mr-md-0 mb-md-4"
                        >
                          <StaticImage src="../images/appstore.png" alt="App Store" width={110} />
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=it.engie.appengie&hl=it&utm_source=website&utm_campaign=app_download&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                          target="_blank"
                          rel="noreferrer"
                          name="Badge Google Play - 5.3 - Footer"
                          onClick={pushAppBanner}
                          className="prefooter__btn d-inline-block d-md-block"
                        >
                          <StaticImage src="../images/playstore.png" alt="Play Store" width={110} />
                        </a>
                      </div>
                    </div>
                  )}
                  <div
                    className={classNames(
                      "col-12 col-md-6 col-lg-2 d-none d-md-block mt-4 mt-lg-0",
                      {
                        "offset-lg-1": i === 0,
                      }
                    )}
                  >
                    <h5>{item.value || item.content?.titlePage?.value?.title?.value}</h5>
                    {!!item.content?.pagine?.length && (
                      <ul>
                        {item.content.pagine.map((child, j) => (
                          <li key={j}>
                            <Link
                              to={
                                child.value?.link ||
                                child.value?.pageLink?.value ||
                                child.value?.document?.node?.publicURL
                              }
                            >
                              {child.content?.pageName?.value || child.value?.title?.value}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </React.Fragment>
              ))}
            </div>
            <div className="row"></div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="footer__content">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-6">
                <span className="footer__label mr-3">Sei in:</span>
                <FooterSites />
              </div>
              <div className="col-12 pt-5 pt-md-0 pb-5 pb-md-0 col-md-4 col-lg-3">
                <div className="container">
                  <div className="row">
                    <div className="col-6 pl-0">
                      <ul className="list-unstyled">
                        {shortFooter?.title
                          ?.slice(0, Math.ceil(shortFooter.title.length / 2))
                          ?.map((item, i) => (
                            <li key={i}>
                              <Link
                                to={
                                  item.content?.page?.value?.link ||
                                  item.content?.page?.value?.pageLink?.value ||
                                  item.content?.page?.value?.document?.node?.publicURL
                                }
                                className="footer__link text-blue-corporate"
                              >
                                {item.value}
                              </Link>
                            </li>
                          ))}
                        <li>
                          <button
                            className="footer__link text-blue-corporate"
                            onClick={onetrustShowSettings}
                          >
                            Impostazione cookie
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="col-6 pl-0">
                      <ul className="list-unstyled">
                        {shortFooter?.title
                          ?.slice(Math.ceil(shortFooter.title.length / 2))
                          ?.map((item, i) => (
                            <li key={i}>
                              <Link
                                to={
                                  item.content?.page?.value?.link ||
                                  item.content?.page?.value?.pageLink?.value ||
                                  item.content?.page?.value?.document?.node?.publicURL
                                }
                                className="footer__link text-blue-corporate"
                              >
                                {item.value}
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-3 pr-sm-7 pr-md-2 pb-5 pb-md-0 d-flex justify-content-between">
                <a
                  href="https://www.facebook.com/engieitalia"
                  className="footer__icon"
                  aria-label="Facebook"
                >
                  <Icon name="facebook-solid" />
                </a>
                <a
                  href="https://www.instagram.com/engieitalia"
                  className="footer__icon"
                  aria-label="Instagram"
                >
                  <Icon name="instagram" />
                </a>
                <a
                  href="https://www.tiktok.com/@engieitalia"
                  className="footer__icon"
                  aria-label="TikTok"
                >
                  <Icon name="tiktok" />
                </a>
                <a
                  href="https://twitter.com/ENGIEitalia"
                  className="footer__icon"
                  aria-label="Twitter"
                >
                  <Icon name="twitter-x" />
                </a>
                <a
                  href="https://www.youtube.com/c/ENGIEitalia"
                  className="footer__icon"
                  aria-label="Youtube"
                >
                  <Icon name="youtube" />
                </a>
                <a
                  href="https://www.linkedin.com/company/engie-italia"
                  className="footer__icon"
                  aria-label="Linkedin"
                >
                  <Icon name="linkedin" />
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-9 col-sm-5 col-lg-4 col-xl-3">
                <span className="footer__info">ENGIE ITALIA S.p.A. - P. IVA 06289781004</span>
              </div>
              <div className="col-3 pr-0 pr-sm-2 col-lg-2">
                <Link to="/mappa-sito/" className="footer__info">
                  Mappa sito
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
