import React, { useMemo } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "../link";
import HUBCaratteristicheProdottoDDL from "../elements/HUBCaratteristicheProdottoDDL";
import withPreview from "../../utility/with-preview";
import { isFlusso, getProductName } from "../../utility/config-flussi-switch-in";
import { dataLayerPush, dataLayerStr2Obj } from "../../utility/dataLayerUtils";
import { handleContentAccessibility } from "../../utility/accessibility";
import Cookies from "js-cookie";

const getDestination = (prod, itemCta) => {
  return itemCta
    ? prod.content.acquistoProdotto.content.ctaAcquistoProdotto.value
        ?.split("(")?.[1]
        ?.split("'")?.[1] + prod.content.parametri.value
    : prod.content.acquistoProdotto.content.ctaAcquistoProdotto.value
        ?.split("href=")?.[1]
        ?.split("'")[1];
};

const handleCta = (ctaValue) => {
  if (isFlusso(ctaValue)) {
    const productName = getProductName(ctaValue);
    const fluxEvent = new CustomEvent("start-flusso-switch-in", {
      detail: {
        productName,
      },
    });
    document.dispatchEvent(fluxEvent);
  } else {
    const functionName = ctaValue.slice(ctaValue.indexOf(":") + 1, ctaValue.indexOf("("));
    const formModalEvent = new CustomEvent("show-form-modal", {
      detail: {
        functionName,
      },
    });
    document.dispatchEvent(formModalEvent);
  }
};

const HubProdottoV2 = ({ data }) => {
  const { statoOfferta } = data;

  console.log(statoOfferta?.[0]?.content?.prodottoCRM);

  const scadenzaValues = useMemo(
    () =>
      statoOfferta?.map((prod, i) =>
        prod?.content?.alertScadenzaProdottoImmagine?.content?.alertScadenzaProdottoImmagineTesto?.value?.replace(
          /@dataFineValidita/g,
          statoOfferta?.[i]?.content?.prodottoCRM?.dataFineValidita
        )
      ),
    [statoOfferta]
  );

  const descriptionValues = useMemo(
    () =>
      statoOfferta?.map((prod, i) =>
        prod?.content?.descrizioneProdotto?.value
          ?.replace(
            /@corrispettivoEnergia/g,
            statoOfferta?.[i]?.content?.prodottoCRM?.corrispettivoEnergia
          )
          ?.replace(/@quotaFissaLuce/g, statoOfferta?.[i]?.content?.prodottoCRM?.quotaFissaLuce)
          ?.replace(/@quotaFissaGas/g, statoOfferta?.[i]?.content?.prodottoCRM?.quotaFissaGas)
          ?.replace(
            /@componentePrezzoFisso/g,
            statoOfferta?.[i]?.content?.prodottoCRM?.componentePrezzoFisso
          )
          ?.replace(/@quotaVariabileGas/g, statoOfferta?.[i]?.content?.prodottoCRM?.quotaFissaGas)
          ?.replace(/@quotaVariabileLuce/g, statoOfferta?.[i]?.content?.prodottoCRM?.quotaFissaGas)

          ?.replace(
            /@quotaAnnualeLuceFissa/g,
            statoOfferta?.[i]?.content?.prodottoCRM?.quotaFissaLuceAnnuale
          )
          ?.replace(
            /@quotaAnnualeGasFissa/g,
            statoOfferta?.[i]?.content?.prodottoCRM?.quotaFissaGasAnnuale
          )
          ?.replace(/@spreadLuce/g, statoOfferta?.[i]?.content?.prodottoCRM?.spreadLuce)
          ?.replace(
            /@consumoLuceLordoPerdite/g,
            statoOfferta?.[i]?.content?.prodottoCRM?.spreadLuceLordoPerdite
          )
          ?.replace(/@spreadGas/g, statoOfferta?.[i]?.content?.prodottoCRM?.spreadGas)
          ?.replace(
            /@quotaCommFissaDomesticoGasMensile/g,
            statoOfferta?.[i]?.content?.prodottoCRM?.quotaCommFissaDomesticoGasMensile
          )
          ?.replace(
            /@quotaCommFissaDomesticoGas/g,
            statoOfferta?.[i]?.content?.prodottoCRM?.quotaCommFissaDomesticoGas
          )
          ?.replace(
            /@quotaCommFissaDomesticoLuceMensile/g,
            statoOfferta?.[i]?.content?.prodottoCRM?.quotaCommFissaDomesticoLuceMensile
          )
          ?.replace(
            /@quotaCommFissaDomesticoLuce/g,
            statoOfferta?.[i]?.content?.prodottoCRM?.quotaCommFissaDomesticoLuce
          )
          ?.replace(
            /@quotaCommVariabileDomesticoGas/g,
            statoOfferta?.[i]?.content?.prodottoCRM?.quotaCommVariabileDomesticoGas
          )
          ?.replace(
            /@quotaCommVariabileDomesticoLuce/g,
            statoOfferta?.[i]?.content?.prodottoCRM?.quotaCommVariabileDomesticoLuce
          )
          ?.replace(/@quotaPvolSmc/g, statoOfferta?.[i]?.content?.prodottoCRM?.quotaPvolSmc)
          ?.replace(/@quotaPvolkWh/g, statoOfferta?.[i]?.content?.prodottoCRM?.quotaPvolkWh)
          ?.replace(/@quotaPvolF1kWh/g, statoOfferta?.[i]?.content?.prodottoCRM?.quotaPvolF1kWh)
          ?.replace(/@quotaPvolF2kWh/g, statoOfferta?.[i]?.content?.prodottoCRM?.quotaPvolF2kWh)
          ?.replace(/@quotaPvolF3kWh/g, statoOfferta?.[i]?.content?.prodottoCRM?.quotaPvolF3kWh)
          ?.replace(/@quotaAlfakWh/g, statoOfferta?.[i]?.content?.prodottoCRM?.quotaAlfakWh)
          ?.replace(/@quotaAlfaSmc/g, statoOfferta?.[i]?.content?.prodottoCRM?.quotaAlfaSmc)
          ?.replace(/@quotaAlfaF1kWh/g, statoOfferta?.[i]?.content?.prodottoCRM?.quotaAlfaF1kWh)
          ?.replace(/@quotaAlfaF2kWh/g, statoOfferta?.[i]?.content?.prodottoCRM?.quotaAlfaF2kWh)
          ?.replace(/@quotaAlfaF3kWh/g, statoOfferta?.[i]?.content?.prodottoCRM?.quotaAlfaF3kWh)
      ),
    [statoOfferta]
  );

  return (
    <section className="section hub-prodotto-v2 wrapper-container px-0">
      <div className="container-fluid px-0">
        {statoOfferta?.map((prod, i) => (
          <div className="row mx-0" key={i}>
            <div className="col-12 px-0 hub-prodotto-v2__prodotto d-md-flex">
              {prod?.content?.immagineProdotto?.node && (
                <div className="hub-prodotto-v2__col hub-prodotto-v2__col--first d-none d-md-block">
                  <div className="hub-prodotto-v2__img-container">
                    <Link
                      to={getDestination(
                        prod,
                        prod.content?.acquistoProdotto?.content?.ctaAcquistoProdotto?.value?.startsWith(
                          "itemCTA"
                        )
                      )}
                      onClick={() => {
                        dataLayerPush(dataLayerStr2Obj(prod.content?.datalayerEvent?.value));
                        //GA4
                        dataLayerPush(dataLayerStr2Obj('{"ecommerce":null}'));
                        dataLayerPush(dataLayerStr2Obj(prod.content?.gaEvent?.value));
                        //
                      }}
                    >
                      <GatsbyImage
                        className="hub-prodotto-v2__img"
                        image={getImage(prod.content.immagineProdotto.node)}
                        alt="Immagine prodotto"
                      />
                    </Link>
                  </div>
                  {prod?.content?.alertScadenzaProdottoImmagine?.value &&
                    prod?.content?.alertScadenzaProdottoImmagine?.content
                      ?.alertScadenzaProdottoImmagineTesto?.value && (
                      <div
                        className="hub-prodotto-v2__scadenza"
                        dangerouslySetInnerHTML={{
                          __html: scadenzaValues[i],
                        }}
                      ></div>
                    )}
                </div>
              )}
              <div className="hub-prodotto-v2__col hub-prodotto-v2__col--center">
                {prod?.content?.nomeProdotto?.value && (
                  <Link
                    to={getDestination(
                      prod,
                      prod.content?.acquistoProdotto?.content?.ctaAcquistoProdotto?.value?.startsWith(
                        "itemCTA"
                      )
                    )}
                    onClick={() => {
                      dataLayerPush(dataLayerStr2Obj(prod.content?.datalayerEvent?.value));
                      //GA4
                      dataLayerPush(dataLayerStr2Obj('{"ecommerce":null}'));
                      dataLayerPush(dataLayerStr2Obj(prod.content?.gaEvent?.value));
                    }}
                  >
                    <div className="hub-prodotto-v2__title">
                      {handleContentAccessibility(prod.content.nomeProdotto.value)}
                    </div>
                  </Link>
                )}
                {prod?.content?.alertScadenzaProdottoImmagine?.value &&
                  prod?.content?.alertScadenzaProdottoImmagine?.content
                    ?.alertScadenzaProdottoImmagineTesto?.value && (
                    <div
                      className="hub-prodotto-v2__scadenza d-md-none"
                      dangerouslySetInnerHTML={{
                        __html: scadenzaValues[i],
                      }}
                    ></div>
                  )}
                {prod?.content?.immagineProdotto?.node && (
                  <Link
                    to={getDestination(
                      prod,
                      prod.content?.acquistoProdotto?.content?.ctaAcquistoProdotto?.value?.startsWith(
                        "itemCTA"
                      )
                    )}
                    onClick={() => {
                      dataLayerPush(dataLayerStr2Obj(prod.content?.datalayerEvent?.value));
                      //GA4
                      dataLayerPush(dataLayerStr2Obj('{"ecommerce":null}'));
                      dataLayerPush(dataLayerStr2Obj(prod.content?.gaEvent?.value));
                    }}
                  >
                    <div className="hub-prodotto-v2__img-container d-md-none">
                      <GatsbyImage
                        className="hub-prodotto-v2__img"
                        image={getImage(prod.content.immagineProdotto.node)}
                        alt="Immagine prodotto"
                      />
                    </div>
                  </Link>
                )}
                {prod?.content?.descrizioneProdotto?.value && (
                  <div
                    className="hub-prodotto-v2__descr"
                    dangerouslySetInnerHTML={{ __html: descriptionValues[i] }}
                  />
                )}
                {prod?.content?.caratteristicheProdotto?.value && (
                  <div className="hub-prodotto-v2__features d-md-flex flex-wrap">
                    {prod.content.caratteristicheProdotto.value
                      .replace(/ /g, "")
                      .split(",")
                      .map((feature, j) => (
                        <div
                          className="hub-prodotto-v2__feature d-flex justify-content-md-center align-items-center"
                          key={j}
                        >
                          {HUBCaratteristicheProdottoDDL[feature]?.icona && (
                            <div
                              className="hub-prodotto-v2__icon"
                              style={{
                                "--bgimage": `url(${HUBCaratteristicheProdottoDDL[feature].icona})`,
                              }}
                            />
                          )}
                          <div className="hub-prodotto-v2__feature-descr">
                            {HUBCaratteristicheProdottoDDL[feature]?.descr}
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
              <div className="hub-prodotto-v2__col hub-prodotto-v2__col--last d-flex flex-column justify-content-start">
                {prod.content?.alertScadenzaProdottoCta?.value &&
                  prod.content?.alertScadenzaProdottoCta?.content?.alertScadenzaProdottoCtaTesto
                    ?.value && (
                    <button className="hub-prodotto-v2__btn-scadenza">
                      {
                        prod.content.alertScadenzaProdottoCta.content.alertScadenzaProdottoCtaTesto
                          .value
                      }
                    </button>
                  )}
                {prod.content?.nameProductSimulatore?.value && prod.content?.activeBtn?.value && (
                  <button
                    title={prod.content.activeBtn.value}
                    onClick={() => {
                      Cookies.set(
                        "simulatoreNomeProdotto",
                        prod.content.nameProductSimulatore.value
                      );
                      window.open("/simulatore-bolletta");
                    }}
                    className="hub-prodotto-v2__cta"
                  >
                    {prod.content.activeBtn.value}
                  </button>
                )}
                {prod.content?.acquistoProdotto?.value &&
                  prod.content?.acquistoProdotto?.content?.ctaAcquistoProdotto?.value &&
                  prod.content?.activeBtn?.value &&
                  (prod.content.acquistoProdotto.content.ctaAcquistoProdotto.value.startsWith(
                    "itemCTA"
                  ) ? (
                    <Link
                      to={getDestination(prod, true)}
                      className="hub-prodotto-v2__cta"
                      onClick={() => {
                        dataLayerPush(dataLayerStr2Obj(prod.content?.datalayerEvent?.value));
                        //GA4
                        dataLayerPush(dataLayerStr2Obj('{"ecommerce":null}'));
                        dataLayerPush(dataLayerStr2Obj(prod.content?.gaEvent?.value));
                      }}
                    >
                      {prod.content.activeBtn.value}
                    </Link>
                  ) : prod.content.acquistoProdotto.content.ctaAcquistoProdotto.value.startsWith(
                      "location.href"
                    ) ? (
                    <Link to={getDestination(prod)} className="hub-prodotto-v2__cta">
                      {prod.content.activeBtn.value}
                    </Link>
                  ) : (
                    <button
                      className="hub-prodotto-v2__cta"
                      onClick={() =>
                        handleCta(prod.content.acquistoProdotto.content.ctaAcquistoProdotto.value)
                      }
                    >
                      {prod.content.activeBtn.value}
                    </button>
                  ))}
                {prod.content?.sottotitoloBtn?.value && (
                  <Link to={getDestination(prod)} className="hub-prodotto-v2__cta-sottotitolo">
                    {prod.content.sottotitoloBtn.value}
                  </Link>
                )}
              </div>
            </div>
            {i < statoOfferta?.length - 1 && <hr className="hub-prodotto-v2__separator" />}
          </div>
        ))}
      </div>
    </section>
  );
};

export default withPreview(HubProdottoV2);
export const fragment = graphql`
  fragment HubProdottoV2Fragment on LiferayHubProdottoV2 {
    liferayFields {
      siteId
      articleId
    }
    statoOfferta {
      value
      content {
        prodottoCRM {
          dataFineValidita
          corrispettivoEnergia
          quotaFissaLuce
          quotaFissaGas
          componentePrezzoFisso
          quotaVariabileGas
          quotaVariabileLuce
          quotaFissaLuceAnnuale
          quotaFissaGasAnnuale
          spreadLuce
          spreadLuceLordoPerdite
          spreadGas
          quotaCommFissaDomesticoGas
          quotaCommFissaDomesticoGasMensile
          quotaCommFissaDomesticoLuce
          quotaCommFissaDomesticoLuceMensile
          quotaCommVariabileDomesticoGas
          quotaCommVariabileDomesticoLuce
          quotaPvolSmc
          quotaPvolkWh
          quotaPvolF1kWh
          quotaPvolF2kWh
          quotaPvolF3kWh
          quotaAlfakWh
          quotaAlfaSmc
          quotaAlfaF1kWh
          quotaAlfaF2kWh
          quotaAlfaF3kWh
        }
        codiceProdotto {
          value
        }
        nomeProdotto {
          value
        }
        descrizioneProdotto {
          value
        }
        activeBtn {
          value
        }
        sottotitoloBtn {
          value
        }
        parametri {
          value
        }
        immagineProdotto {
          node {
            gatsbyImageData(width: 690)
          }
        }
        caratteristicheProdotto {
          value
        }
        testoCorrelati {
          value
        }
        prodottiCorrelati {
          value
        }
        paginaDettaglioProdotto {
          value
        }
        nameProductSimulatore {
          value
        }
        acquistoProdotto {
          value
          content {
            ctaAcquistoProdotto {
              value
            }
          }
        }
        alertScadenzaProdottoImmagine {
          value
          content {
            alertScadenzaProdottoImmagineTesto {
              value
            }
          }
        }
        alertScadenzaProdottoCta {
          value
          content {
            alertScadenzaProdottoCtaTesto {
              value
            }
          }
        }
        datalayerEvent {
          value
        }
        gaEvent {
          value
        }
      }
    }
    immagineBannerDesktop {
      node {
        publicURL
      }
    }
    immagineBannerMobile {
      node {
        publicURL
      }
    }
    scriptBanner {
      value
    }
    scriptGenerico {
      value
    }
  }
`;
