import React, { useMemo } from "react";
import HUBCaratteristicheProdottoDDL from "../elements/HUBCaratteristicheProdottoDDL";
import withPreview from "../../utility/with-preview";
import { graphql } from "gatsby";

const PersonalizzazioneOffertaCard = ({ data, fornitura }) => {
  const { cardTitle, productFeatures, labelFieldEle, labelFieldGas, prodottoCRM } = data;

  const eleFeatures = useMemo(() => {
    const features = labelFieldEle?.map((element) => ({
      label: element?.value,
      value: element?.content?.valueFieldEle?.value
        ?.replace(
          /@corrispettivoEnergia/g,
          prodottoCRM?.corrispettivoEnergia ? prodottoCRM.corrispettivoEnergia : ""
        )
        ?.replace(
          /@corrispettivoF1/g,
          prodottoCRM?.corrispettivoF1 ? prodottoCRM.corrispettivoF1 : ""
        )
        ?.replace(
          /@corrispettivoF2/g,
          prodottoCRM?.corrispettivoF2 ? prodottoCRM.corrispettivoF2 : ""
        )
        ?.replace(
          /@corrispettivoF3/g,
          prodottoCRM?.corrispettivoF3 ? prodottoCRM.corrispettivoF3 : ""
        )
        ?.replace(/@spreadLuce/g, prodottoCRM?.spreadLuce ? prodottoCRM.spreadLuce : "")
        ?.replace(
          /@consumoLuceLordoPerdite/g,
          prodottoCRM?.spreadLuceLordoPerdite ? prodottoCRM.spreadLuceLordoPerdite : ""
        )
        ?.replace(/@spreadF1/g, prodottoCRM?.spreadF1 ? prodottoCRM.spreadF1 : "")
        ?.replace(/@spreadF2/g, prodottoCRM?.spreadF2 ? prodottoCRM.spreadF2 : "")
        ?.replace(/@spreadF3/g, prodottoCRM?.spreadF3 ? prodottoCRM.spreadF3 : "")
        ?.replace(/@quotaPvolkWh/g, prodottoCRM?.quotaPvolkWh ? prodottoCRM.quotaPvolkWh : "")
        ?.replace(/@quotaPvolF1kWh/g, prodottoCRM?.quotaPvolF1kWh ? prodottoCRM.quotaPvolF1kWh : "")
        ?.replace(/@quotaPvolF2kWh/g, prodottoCRM?.quotaPvolF2kWh ? prodottoCRM.quotaPvolF2kWh : "")
        ?.replace(/@quotaPvolF3kWh/g, prodottoCRM?.quotaPvolF3kWh ? prodottoCRM.quotaPvolF3kWh : "")
        ?.replace(/@quotaAlfakWh/g, prodottoCRM?.quotaAlfakWh ? prodottoCRM.quotaAlfakWh : "")
        ?.replace(/@quotaAlfaF1kWh/g, prodottoCRM?.quotaAlfaF1kWh ? prodottoCRM.quotaAlfaF1kWh : "")
        ?.replace(/@quotaAlfaF2kWh/g, prodottoCRM?.quotaAlfaF2kWh ? prodottoCRM.quotaAlfaF2kWh : "")
        ?.replace(/@quotaAlfaF3kWh/g, prodottoCRM?.quotaAlfaF3kWh ? prodottoCRM.quotaAlfaF3kWh : "")
        ?.replace(
          /@corrispettivoBiorariaF1/g,
          prodottoCRM?.corrispettivoBiorariaF1 ? prodottoCRM.corrispettivoBiorariaF1 : ""
        )
        ?.replace(
          /@corrispettivoBiorariaF23/g,
          prodottoCRM?.corrispettivoBiorariaF23 ? prodottoCRM.corrispettivoBiorariaF23 : ""
        )
        ?.replace(
          /@quotaCommFissaDomesticoLuceMensile/g,
          prodottoCRM?.quotaCommFissaDomesticoLuceMensile
            ? prodottoCRM.quotaCommFissaDomesticoLuceMensile
            : ""
        )
        ?.replace(
          /@quotaCommFissaDomesticoLuce/g,
          prodottoCRM?.quotaCommFissaDomesticoLuce ? prodottoCRM.quotaCommFissaDomesticoLuce : ""
        )
        ?.replace(
          /@quotaCommVariabileDomesticoLuce/g,
          prodottoCRM?.quotaCommVariabileDomesticoLuce
            ? prodottoCRM.quotaCommVariabileDomesticoLuce
            : ""
        ),
    }));
    // .filter((item) => !value.includes("@"));

    return features;
  }, [prodottoCRM, labelFieldEle]);

  const gasFeatures = useMemo(() => {
    const features = labelFieldGas?.map((element) => ({
      label: element?.value,
      value: element?.content?.valueFieldGas?.value
        ?.replace(
          /@componentePrezzoFisso/g,
          prodottoCRM?.componentePrezzoFisso ? prodottoCRM.componentePrezzoFisso : ""
        )
        ?.replace(/@quotaPvolSmc/g, prodottoCRM?.quotaPvolSmc ? prodottoCRM.quotaPvolSmc : "")
        ?.replace(/@spreadGas/g, prodottoCRM?.spreadGas ? prodottoCRM.spreadGas : "")
        ?.replace(
          /@quotaCommFissaDomesticoGasMensile/g,
          prodottoCRM?.quotaCommFissaDomesticoGasMensile
            ? prodottoCRM.quotaCommFissaDomesticoGasMensile
            : ""
        )
        ?.replace(
          /@quotaCommFissaDomesticoGas/g,
          prodottoCRM?.quotaCommFissaDomesticoGas ? prodottoCRM.quotaCommFissaDomesticoGas : ""
        )
        ?.replace(
          /@quotaCommVariabileDomesticoGas/g,
          prodottoCRM?.quotaCommVariabileDomesticoGas
            ? prodottoCRM.quotaCommVariabileDomesticoGas
            : ""
        )
        ?.replace(/@quotaAlfaSmc/g, prodottoCRM?.quotaAlfaSmc ? prodottoCRM.quotaAlfaSmc : ""),
    }));
    // .filter((item) => !value.includes("@"));

    return features;
  }, [prodottoCRM, labelFieldGas]);

  return (
    <section className="section personalizzazione-offerta-card">
      <div className="personalizzazione-offerta-card__container">
        {cardTitle?.value && (
          <h2 className="personalizzazione-offerta-card__title">{cardTitle.value}</h2>
        )}
        <div className="personalizzazione-offerta-card__content">
          <div className="personalizzazione-offerta-card__commodity-container">
            {(fornitura === "ele" || fornitura === "dual") && eleFeatures?.length > 0 && (
              <div className="personalizzazione-offerta-card__commodity">
                {eleFeatures.map((feature, key) => (
                  <div key={key} className="personalizzazione-offerta-card__commodity-item">
                    <span className="personalizzazione-offerta-card__commodity-label">
                      {feature.label}
                    </span>
                    <div
                      className="personalizzazione-offerta-card__commodity-value"
                      dangerouslySetInnerHTML={{ __html: feature.value }}
                    />
                  </div>
                ))}
              </div>
            )}
            {fornitura === "dual" && <hr className="personalizzazione-offerta-card__separator" />}
            {(fornitura === "gas" || fornitura === "dual") && gasFeatures?.length > 0 && (
              <div className="personalizzazione-offerta-card__commodity">
                {gasFeatures.map((feature, key) => (
                  <div key={key} className="personalizzazione-offerta-card__commodity-item">
                    <span className="personalizzazione-offerta-card__commodity-label">
                      {feature.label}
                    </span>
                    <div
                      className="personalizzazione-offerta-card__commodity-value"
                      dangerouslySetInnerHTML={{ __html: feature.value }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          {productFeatures?.value && (
            <div className="personalizzazione-offerta-card__features d-flex flex-column">
              {productFeatures.value
                .replace(/ /g, "")
                .split(",")
                .filter(
                  (element) => (element === "green" && fornitura !== "gas") || element !== "green"
                )
                .map((feature, i) => (
                  <div
                    className="personalizzazione-offerta-card__feature d-flex align-items-center"
                    key={i}
                  >
                    {HUBCaratteristicheProdottoDDL[feature]?.icona && (
                      <div
                        className="personalizzazione-offerta-card__icon"
                        style={{
                          "--bgimage": `url(${HUBCaratteristicheProdottoDDL[feature].icona})`,
                        }}
                      />
                    )}
                    <div className="personalizzazione-offerta-card__feature-description">
                      {HUBCaratteristicheProdottoDDL[feature]?.descr}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default withPreview(PersonalizzazioneOffertaCard);
export const fragment = graphql`
  fragment JskPersonalizzazioneOffertaCardFragment on LiferayJskPersonalizzazioneOffertaCard {
    liferayFields {
      siteId
      articleId
    }
    cardTitle {
      value
    }
    crmProdId {
      value
    }
    productFeatures {
      value
    }
    labelFieldEle {
      value
      content {
        valueFieldEle {
          value
        }
      }
    }
    labelFieldGas {
      value
      content {
        valueFieldGas {
          value
        }
      }
    }
    prodottoCRM {
      dataFineValidita
      corrispettivoEnergia
      corrispettivoF1
      corrispettivoF2
      corrispettivoF3
      componentePrezzoFisso
      spreadF1
      spreadF2
      spreadF3
      corrispettivoBiorariaF1
      corrispettivoBiorariaF23
      quotaCommFissaDomesticoGas
      quotaCommFissaDomesticoGasMensile
      quotaCommFissaDomesticoLuce
      quotaCommFissaDomesticoLuceMensile
      quotaCommVariabileDomesticoGas
      quotaCommVariabileDomesticoLuce
      quotaPvolSmc
      quotaPvolkWh
      quotaPvolF1kWh
      quotaPvolF2kWh
      quotaPvolF3kWh
      quotaAlfakWh
      quotaAlfaF1kWh
      quotaAlfaF2kWh
      quotaAlfaF3kWh
      spreadGas
      spreadLuce
      spreadLuceLordoPerdite
      quotaAlfaSmc
    }
  }
`;
